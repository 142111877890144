import React, { useContext, useState, useEffect, createContext, useRef } from 'react';
import api from '../hooks/api';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const cache = useRef(new Map());
    const [theme, setTheme] = useState('light');

    // Helper function to save cache data to local storage
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    // Helper function to load cache data from local storage
    const loadFromLocalStorage = (key) => {
        const storedValue = localStorage.getItem(key);
        try {
            return storedValue ? JSON.parse(storedValue) : null;
        } catch (e) {
            console.error(`Error parsing localStorage key "${key}":`, e);
            return null;
        }
    };

    useEffect(() => {
        // On mount, try to load Spotify tokens and user from local storage
        const spotifyToken = loadFromLocalStorage('spotifyToken');
        const spotifyRefreshToken = loadFromLocalStorage('spotifyRefreshToken');
        const spotifyUser = loadFromLocalStorage('spotifyUser');

        if (spotifyToken) cache.current.set('spotifyToken', spotifyToken);
        if (spotifyRefreshToken) cache.current.set('spotifyRefreshToken', spotifyRefreshToken);
        if (spotifyUser) cache.current.set('spotifyUser', spotifyUser);
    }, []);

    const checkSpotifyToken = async () => {
        const code = new URLSearchParams(window.location.search).get('code');
        console.log(code);
        console.log(cache);

        if (code && !cache.current.has('spotifyToken')) {
            const response = await api.post('/api/auth/spotifyLogin', {
                code,
            });
            const { accessToken, refreshToken, user } = response.data;

            // Set cache
            cache.current.set('spotifyToken', accessToken);
            cache.current.set('spotifyRefreshToken', refreshToken);
            cache.current.set('spotifyUser', user);

            // Save to local storage
            saveToLocalStorage('spotifyToken', accessToken);
            saveToLocalStorage('spotifyRefreshToken', refreshToken);
            saveToLocalStorage('spotifyUser', user);

            return { accessToken, user , refreshToken};
        } else if (cache.current.has('spotifyToken')) {
            return {
                accessToken: cache.current.get('spotifyToken'),
                user: cache.current.get('spotifyUser')
            };
        }

        // Clean up the URL
        window.history.pushState({}, null, '/Spotify');
        return {};
    };
    async function refreshAccessToken() {
        try {
            const oldAccessToken = cache.current.get('spotifyToken');
            const oldRefreshToken = cache.current.get('spotifyRefreshToken');
            const response = await api.get(`/api/refreshAccessToken/${oldAccessToken}/${oldRefreshToken}`);
            const { accessToken, refreshToken } = response.data;
            // Update cache
            cache.current.set('spotifyToken', accessToken);
            cache.current.set('spotifyRefreshToken', refreshToken);
            // Save to local storage
            saveToLocalStorage('spotifyToken', accessToken);
            saveToLocalStorage('spotifyRefreshToken', refreshToken);
            return accessToken;
        }
        catch (err) {
            console.error(err);
            return null;
        }
    }
    async function clearCache () {
        try {
            cache.current.clear();
            // Clear local storage
            localStorage.clear();
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }

    return (
        <UserContext.Provider value={{
            cache,
            checkSpotifyToken,
            refreshAccessToken,
            clearCache,
            theme,
            setTheme,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export {
    UserContext,
    UserProvider
};
